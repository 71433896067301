import { gql, useQuery, useSubscription } from "@apollo/client";
import { useMemo } from "react";

export interface GroupAnimal {
  access: string;
  animalId: string;
  customTags?: string[];
  animal: {
    name: string;
    sex: string;
    species: string;
    created: string;
    updated: string;
  };
}

export const useGroupAnimalsQuery = `query GetGroupAnimals($groupId: ID!) {
  getAnimalByGroup(groupId: $groupId) {
    items {
      access
      animalId
      customTags
      animal {
        name
        sex
        species
        created
        updated
      }
    }
  }
}`;

export const useGroupAnimals = (groupId?: string) => {
  const { data, loading, refetch, ...query } = useQuery(
    gql(useGroupAnimalsQuery),
    {
      variables: { groupId },
    }
  );

  useSubscription(
    gql`
      subscription GroupAnimalSubscription($groupId: ID!) {
        onAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          created
          groupId
          customTags
          animal {
            animalId
            breed
            colour
            created
            dob
            implantHwVersion
            implantModel
            implantSwVersion
            microchip
            name
            notes
            photo
            sex
            species
            updated
            weight
          }
          notifications {
            animalId
            body
            data
            notificationType
            resolved
            timestamp
            title
          }
          readings {
            animalId
            booleanValue
            datetime
            floatValue
            intValue
            stringValue
            type
          }
          vetLinks {
            animalId
            created
            orgId
            orgName
            vetId
          }
        }
      }
    `,
    {
      variables: { groupId },
      onData: (data) => refetch(),
    }
  );

  useSubscription(
    gql`
      subscription RemoveGroupAnimalSubscription($groupId: ID!) {
        onRemoveAnimalEventByGroup(groupId: $groupId) {
          access
          animalId
          created
          groupId
        }
      }
    `,
    { variables: { groupId }, onData: (data) => refetch() }
  );

  const groupAnimals: GroupAnimal[] = useMemo(
    () => data?.getAnimalByGroup?.items ?? [],
    [data]
  );
  return { loading, groupAnimals, ...query, refresh: refetch };
};
